<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Name"
              invalid-feedback="Name is required."
              ref="name"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter name here"
                v-validate="'required'"
                ref="name"
                v-model="myObj.name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Email"
              invalid-feedback="Email is required."
              ref="email"
            >
              <b-form-input
                placeholder="Enter email name here"
                ref="email"
                v-model="myObj.email"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Contact"
              invalid-feedback="Contact number is required."
            >
              <vue-tel-input v-model="myObj.contact"></vue-tel-input>
              <!-- @blur="CheckContact()" -->
              <small class="mr-1 text-danger" v-if="contacterror"
                >Contact number is required</small
              >
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Mobile"
              invalid-feedback="Mobile number is required."
            >
              <vue-tel-input v-model="myObj.mobile"></vue-tel-input>
              <!-- @blur="CheckMobile()" -->
              <small class="mr-1 text-danger" v-if="mobileerror"
                >Mobile number is required</small
              >
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="City"
              invalid-feedback="City is required."
              ref="city"
            >
              <b-form-input
                placeholder="Enter city name here"
                ref="city"
                v-model="myObj.city"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Address"
              invalid-feedback="Address is required."
              ref="address"
            >
              <b-form-input
                placeholder="Enter address name here"
                ref="address"
                v-model="myObj.address"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Category"
              invalid-feedback="Category  is required."
              ref="category"
            >
              <v-select
                ref="category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="cateOptions"
                :reduce="(opt) => opt.value"
                label="text"
                v-model="myObj.category"
                placeholder="Select category"
                @change="CheckCategory()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Tax Percentage"
              invalid-feedback="Tax Percentage is required."
              ref="tax"
            >
              <b-form-input
                type="number"
                placeholder="Enter tax %"
                ref="tax"
                v-model="myObj.tax"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row class="mt-1 mb-1">
        <b-col xl="2" lg="3" md="4" cols="12" v-if="rights.add">
          <b-button
            @click="Edit(0)"
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1 mb-md-0"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Journal</span>
          </b-button>
        </b-col>

        <b-col xl="4" lg="4" md="8" cols="12" class="mb-1 mb-lg-0">
          <b-form-group class="mb-0">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col xl="2" lg="2" md="4" cols="12" class="p-lg-0">
          <b-form-group
            label=""
            invalid-feedback="Status is required."
            ref="status"
            class=""
          >
            <v-select
              ref="status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="statusOptions"
              :reduce="(opt) => opt.value"
              label="text"
              v-model="filterStatus"
              placeholder="Select filter"
              @input="setStatus()"
            />
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="3"
          md="8"
          cols="12"
          class="d-md-flex align-items-start"
        >
          <b-form-group
            label=""
            invalid-feedback="Date is required."
            ref="fil_date"
            class="mb-1 mb-md-0 mr-md-1"
          >
            <flat-pickr
              ref="fil_date"
              v-model="searchDate"
              :config="config"
              class="form-control"
              placeholder="Filter by Date"
            />
          </b-form-group>
          <b-button
            style="flex: 1"
            @click="filterData()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            :disabled="dataLoading"
          >
            <span class="align-middle">Show</span>
          </b-button>
        </b-col>
        <!-- <b-col xl="2" lg="3" md="4" cols="12">
          <b-button
            @click="filterData()"
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1 mb-md-0"
          >
            <span class="align-middle">Show</span>
          </b-button>
        </b-col> -->
      </b-row>

      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        responsive
        show-empty
        hover
        :selectable="rights.edit"
        select-mode="single"
        @row-selected="Edit($event[0])"
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(voucherNum)="data">
          <b-badge variant="light-primary">
            {{ data.item.entry.voucherNum }}
          </b-badge>
        </template>
        <template #cell(date)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.item.entry.date).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
          <br />
          <b-badge
            variant="light-danger"
            class="mt-50"
            v-if="data.item.entry.status && data.item.entry.status != 'active'"
          >
            {{ data.item.entry.status }}
          </b-badge>
        </template>
        <template #cell(supplier)="data">
          <b-badge variant="light-primary">
            {{ data.item.supplier }}
          </b-badge>
        </template>
        <template #cell(account)="data">
          <div v-for="rec in data.item.details" :key="rec">
            <b-badge variant="light-primary" class="mb-50">
              {{ rec.meta_accountTitle }}
            </b-badge>
          </div>
        </template>
        <template #cell(debit)="data">
          <div v-for="rec in data.item.details" :key="rec">
            <b-badge variant="light-primary" class="mb-50">
              {{ rec.debit }}
            </b-badge>
          </div>
          <!-- style="border-top: 1px solid #6e6b7b" -->
          <div class="border-top-secondary pt-50">
            <label class="font-weight-bold mr-50">Total:</label>
            <b-badge variant="light-primary" class="">
              {{ debitTotal(data.item.details) }}
            </b-badge>
          </div>
        </template>
        <template #cell(credit)="data">
          <div v-for="rec in data.item.details" :key="rec">
            <b-badge variant="light-primary" class="mb-50">
              {{ rec.credit }}
            </b-badge>
          </div>
          <div class="border-top-secondary pt-50">
            <label class="font-weight-bold mr-50">Total:</label>
            <b-badge variant="light-primary" class="">
              {{ creditTotal(data.item.details) }}
            </b-badge>
          </div>
        </template>
        <template #cell(chequeNo)="data">
          <div v-for="rec in data.item.details" :key="rec">
            <b-badge variant="light-primary" class="mb-50">
              {{ rec.chequeNo }}
            </b-badge>
          </div>
        </template>
        <template #cell(note)="data">
          <div v-for="rec in data.item.details" :key="rec">
            <b-badge variant="light-primary" class="mb-50">
              {{ rec.description }}
            </b-badge>
          </div>
        </template>

        <template #head(actions)="data">
          <div class="text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Edit"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Edit(data.item)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <b-button
              v-if="data.item.entry.status == 'approved'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Print Cheque"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="print(data.item.entry.id)"
            >
              <feather-icon icon="PrinterIcon" class="" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Print Voucher"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="printVoucher(data.item.entry.id)"
            >
              <feather-icon icon="FileTextIcon" class="" />
            </b-button>

            <b-button
              v-if="rights.delete"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.right
              title="Delete"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Delete(data.item.entry.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    flatPickr,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      // this.LoadData();
      this.filterData();
    }
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      searchDate: "",
      rights: {},
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Account",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "Voucher#", key: "voucherNum", tdClass: "setCell" },
        { label: "date", key: "date", tdClass: "setCell" },
        // { label: "supplier", key: "supplier", tdClass: "setCell" },
        { label: "account", key: "account", tdClass: "setCell" },
        { label: "debit", key: "debit" },
        { label: "credit", key: "credit" },
        { label: "cheque#", key: "chequeNo", tdClass: "setCell" },
        { label: "note", key: "note", tdClass: "setCell" },
        { label: "actions", key: "actions", tdClass: "setCell p-1" },
      ],
      // items: [
      //   {
      //     id: 1,
      //     voucherNum: "1",
      //     date: new Date(),
      //     supplier: "Ali",
      //     details: [
      //       {
      //         id: 0,
      //         account: "Income",
      //         debit: 2000,
      //         credit: 0,
      //         chequeNo: "1245",
      //         note: "hsjasj",
      //         campusID: this.$store.state.userData.cId,
      //       },
      //       {
      //         id: 1,
      //         account: "Bills",
      //         debit: 0,
      //         credit: 4590,
      //         chequeNo: "2342",
      //         note: "hsjasj",
      //         campusID: this.$store.state.userData.cId,
      //       },
      //     ],
      //   },
      //   {
      //     id: 2,
      //     voucherNum: "2",
      //     date: new Date(),
      //     supplier: "Ahmed",
      //     details: [
      //       {
      //         id: 0,
      //         account: "Software",
      //         debit: 12000,
      //         credit: 0,
      //         chequeNo: "33524",
      //         note: "hshgdg",
      //         campusID: this.$store.state.userData.cId,
      //       },
      //       {
      //         id: 0,
      //         account: "Software",
      //         debit: 12000,
      //         credit: 0,
      //         chequeNo: "33524",
      //         note: "hshgdg",
      //         campusID: this.$store.state.userData.cId,
      //       },
      //       {
      //         id: 0,
      //         account: "Software",
      //         debit: 12000,
      //         credit: 0,
      //         chequeNo: "33524",
      //         note: "hshgdg",
      //         campusID: this.$store.state.userData.cId,
      //       },
      //     ],
      //   },
      // ],
      items: [],
      cateOptions: [
        { text: "Service provider", value: "service" },
        { text: "Products", value: "products" },
      ],
      selected: null,
      rangeDate: null,
      myObj: {
        id: 0,
        name: "",
        contact: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        category: "",
        tax: 0,
        campusID: this.$store.state.userData.cId,
      },

      detailObj: {},
      timeout: 10000,
      statusOptions: [
        { text: "All", value: "" },
        { text: "Pending", value: "pending" },
        { text: "Approved", value: "approved" },
        { text: "Declined", value: "declined" },
      ],
      filterStatus: this.$store.state.journalStatus,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    print(id) {
      var url =
        `https://${this.reportDomain}.myskool.app/Account/PrintCheque?entryID=` +
        id +
        "&dbb=" +
        this.$store.state.userData.db +
        "&cID=" +
        this.$store.state.userData.cId +
        "&title=cash";

      window.open(url, "_blank");
    },

    printVoucher(id) {
      var url =
        `https://${this.reportDomain}.myskool.app/Account/PrintEntryVoucher?entryID=` +
        id +
        "&dbb=" +
        this.$store.state.userData.db +
        "&cID=" +
        this.$store.state.userData.cId;

      window.open(url, "_blank");
    },

    creditTotal(items) {
      let total = items.reduce(
        (sum, el) => (el.credit ? sum + el.credit : sum + 0),
        0
      );
      return total;
    },
    debitTotal(items) {
      let total = items.reduce(
        (sum, el) => (el.debit ? sum + el.debit : sum + 0),
        0
      );
      return total;
    },

    async Edit(item) {
      if (item == 0) {
        this.$router.push({ name: "accounting", params: { id: 0 } });
      } else {
        // console.log(item);
        this.$router.push({
          name: "accounting",
          params: { id: item.entry.id, data: item },
        });
      }
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        name: "",
        contact: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        category: "",
        tax: 0,
        campusID: this.$store.state.userData.cId,
      };

      this.visibility = true;
      this.sidebarTitle = "Add Supplier";
      this.sidebarButton = "Save";
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["email"];
      elem.state = undefined;
      var elem = this.$refs["address"];
      elem.state = undefined;
      var elem = this.$refs["city"];
      elem.state = undefined;
      var elem = this.$refs["category"];
      elem.state = undefined;
      var elem = this.$refs["tax"];
      elem.state = undefined;
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTax() {
      var elem = this.$refs["tax"];
      if (isNaN(parseInt(this.myObj.tax))) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCode() {
      var elem = this.$refs["code"];
      if (this.myObj.code === "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckContact() {
      if (this.myObj.contact.length == 12) {
        return (this.contacterror = false);
      } else {
        return (this.contacterror = true);
      }
    },
    CheckMobile() {
      if (this.myObj.mobile.length == 12) {
        return (this.mobileerror = false);
      } else {
        return (this.mobileerror = true);
      }
    },
    CheckCategory() {
      var elem = this.$refs["category"];
      if (this.myObj.category == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "JournalEntry/LoadData?cID=" +
          this.$store.state.userData.cId +
          "&db=" +
          this.$store.state.userData.db +
          "&dtFrom=&dtTo=",
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      // console.log("it", this.items);

      this.dataLoading = false;
    },

    setStatus() {
      this.$store.commit("setJournalStatus", this.filterStatus);
    },

    async filterData() {
      if (this.filterStatus == "") {
        let date = this.searchDate.split(" to ");
        let df = null;
        let dt = null;

        // console.log(date);
        if (date[0] == "") this.LoadData();
        else {
          var elem = this.$refs["fil_date"];
          if (date.length == 1) {
            return (elem.state = false);
          } else {
            df = date[0];
            dt = date[1];
          }
          // console.log(df, dt);
          if (!df || !dt) {
            elem.state = false;
          } else {
            elem.state = undefined;

            this.dataLoading = true;
            var obj = {
              url:
                this.$store.state.domain +
                "JournalEntry/LoadData?cID=" +
                this.$store.state.userData.cId +
                "&db=" +
                this.$store.state.userData.db +
                "&dtFrom=" +
                df +
                "&dtTo=" +
                dt,
              token: this.$store.state.userData.token,
            };
            this.items = await this.get(obj);
            // console.log("it", this.items);
            this.dataLoading = false;
          }
        }
      } else {
        this.dataLoading = true;
        var obj = {
          url:
            this.$store.state.domain +
            "JournalEntry/LoadDataByStatus?cID=" +
            this.$store.state.userData.cId +
            "&db=" +
            this.$store.state.userData.db +
            "&status=" +
            this.filterStatus,
          token: this.$store.state.userData.token,
        };
        this.items = await this.get(obj);
        // console.log("it", this.items);
        this.dataLoading = false;
      }
    },

    async Add() {
      this.CheckName();
      this.CheckTax();
      this.CheckContact();
      this.CheckCategory();
      if (
        this.CheckName() == true &&
        this.CheckContact() == true &&
        this.CheckTax() == true &&
        this.CheckCategory() == true
      ) {
        this.myObj.tax = parseInt(this.myObj.tax);
        console.log("obj:", this.myObj);
        // this.request = true;
        // var status = await this.post({
        //   url:
        //     this.$store.state.domain +
        //     "?db=" +
        //     this.$store.state.userData.db +
        //     "&cID=" +
        //     this.$store.state.userData.cId,
        //   body: this.myObj,
        //   message: "Supplier added successfully.",
        //   context: this,
        //   token: this.$store.state.userData.token,
        // });
        // this.request = false;
        // if (status) {
        //   this.LoadData();
        //   this.visibility = false;
        // }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        // console.log(id);
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "JournalEntry/DeleteVoucher?cID=" +
            this.$store.state.userData.cId +
            "&db=" +
            this.$store.state.userData.db +
            "&id=" +
            id,
          message: "Journal deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.filterData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
